var $class="se2--footer-se--subscribe-form",$name="FooterSE/subscribeForm",$path="app/components/FooterSE/partials/subscribeForm/index.js",$this={$class,$name,$path,};import onFormSubmitMessage from 'app/components/utilities/onFormSubmitMessage';
import validateFormElements from 'app/components/utilities/validateFormElements';

import analytics from 'app/modules/analytics';
import shell from 'app/modules/shell';

import { css, messages } from 'configs';
import {
  footerCheckboxClassName,
  footerCheckboxContainerSelector,
  footerChinaCheckboxClassName,
  inputCheckboxSelector,
} from 'app/components/FooterSE/config';

export default shell.registerPartial($this, ({ addEventListener, mount, subscribeToMessage }) => {
  let form = null;

  const trackAnalytics = () => analytics.trackNewsletterSubscription({ siteLocationKey: 'Footer' });

  mount((element) => {
    form = element.querySelector('form');
    const footerCheckboxContainerElement = form
      .querySelector(footerCheckboxContainerSelector);
    const footerInputCheckboxElement = form
      .querySelector(`${footerCheckboxContainerSelector} ${inputCheckboxSelector}`);
    const submitButtonElement = form
      .querySelector(`.${css.classNames.submitButton}`);

    // eslint-disable-next-line no-return-assign
    const enableSubmitIfCheckboxPresents = () => submitButtonElement
      .disabled = !(submitButtonElement && footerInputCheckboxElement?.checked);

    const showCheckboxIfCheckboxPresents = ({ resolved, recaptchaId }) => {
      if (!form.querySelector(`#${recaptchaId}`) || !footerCheckboxContainerElement) return;
      if (resolved === true && footerCheckboxContainerElement) {
        footerCheckboxContainerElement.classList.remove(css.classNames.hide);
      }
    };

    if (footerCheckboxContainerElement?.classList.contains(footerCheckboxClassName)) {
      subscribeToMessage(messages.recaptcha, showCheckboxIfCheckboxPresents);
    } else if (footerCheckboxContainerElement?.classList.contains(footerChinaCheckboxClassName)) {
      footerCheckboxContainerElement.classList.remove(css.classNames.hide);
      submitButtonElement.disabled = true;
    }

    subscribeToMessage(
      messages.formSubmit,
      ({ formId, status }) => onFormSubmitMessage(form, formId, status, trackAnalytics),
    );

    addEventListener(footerInputCheckboxElement, 'click', enableSubmitIfCheckboxPresents);
    addEventListener(form, 'submit', validateFormElements);
    addEventListener(submitButtonElement, 'click', () => validateFormElements(form));
  });
});
